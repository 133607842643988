import { FC } from 'react';

import * as s from 'dsl_web/components/Loading.module.scss';

interface LoadingProps {
    scale?: number;
    paused?: boolean;
    color?: string;
    borderScale?: number;
    bgColor?: string;
    design?: 'default' | 'dev';
}

const Loading: FC<LoadingProps> = ({
    color = 'black',
    scale = 1,
    bgColor = 'white',
    borderScale = 2,
    paused = false,
    design = 'default',
    ...rest
}) => {
    const loaderSize = `scale-${scale}`;
    const loaderBgColor = `bgColor-${bgColor}`;
    const loaderBorderScale = `borderScale-${borderScale}`;

    return design === 'dev' ? (
        <div className={`${s.loader} ${s.spinner} ${s[loaderSize]}`} />
    ) : (
        <div
            className={`${s.loader} ${s[color]} ${s[loaderSize]}
                ${s[loaderBgColor]} ${s[loaderBorderScale]}
                ${paused && s.pauseAnimation}`}
            {...rest}
        />
    );
};

export default Loading;
