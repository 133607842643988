import { FC, ReactNode, ButtonHTMLAttributes } from 'react';

import * as s from 'dsl_web/components/Button.module.scss';
import Loader from 'dsl_web/components/Loading.tsx';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    rounded?: boolean | string;
    children?: ReactNode;
    loading?: boolean;
    arrowBtn?: boolean;
    direction?: string;
    textColor?: string;
    color?: string;
    disabled?: boolean;
    shadowColor?: string;
    btnPlacement?: string;
    loaderColor?: string;
    btnFullSizeResponsive?: boolean;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onMouseDown?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    style?: React.CSSProperties;
    loaderDesign?: 'default' | 'dev';
    loaderScale?: number;
}

const Button: FC<ButtonProps> = ({
    rounded = false,
    children,
    loading = false,
    arrowBtn,
    direction = 'down',
    textColor = 'white',
    color = 'blue',
    disabled,
    shadowColor = '',
    btnPlacement = '',
    loaderColor,
    btnFullSizeResponsive = false,
    type = 'button',
    loaderDesign = 'default',
    loaderScale = 1,
    ...rest
}) => {
    const finalClasses = [
        s.btn,
        rounded === true ? s.rounded : rounded ? s[rounded] : undefined,
        s[color],
        s[shadowColor],
        s[btnPlacement],
        btnFullSizeResponsive ? s.btnFullSizeResponsive : undefined,
        loading ? s.lightGray : undefined,
    ];

    return (
        <button className={finalClasses.join(' ')} disabled={loading || disabled} type={type} {...rest}>
            {loading ? <Loader design={loaderDesign} scale={loaderScale} bgColor={loaderColor || 'gray'} /> : children}
            {arrowBtn && (
                <div className={s.arrowBtn}>
                    <i className={`${s.arrow} ${s.down}`} />
                </div>
            )}
        </button>
    );
};

export default Button;
