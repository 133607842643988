import { FC, Ref, HTMLProps } from 'react';

import * as s from 'dsl_web/components/Text.module.scss';

interface TextProps extends HTMLProps<HTMLParagraphElement> {
    fontWeight?: string;
    color?: string;
    reference?: Ref<HTMLParagraphElement>;
}

const Text: FC<TextProps> = ({
    fontWeight = 'normal',
    color = 'darkGray',
    className,
    reference,
    children,
    ...rest
}) => {
    return (
        <p ref={reference} className={`${s[fontWeight]} ${s[color]} ${className} ${s.text}`} {...rest}>
            {children}
        </p>
    );
};

export default Text;
