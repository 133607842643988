.loader {
    margin: auto;
    display: flex;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    animation: spin 1s linear infinite;

    &::before {
        content: '';
        aspect-ratio: 1 / 1;
        margin: auto;
        border-radius: 50%;
    }

    &.bgColor-white {
        &::before {
            background-color: #fff;
        }
    }

    &.bgColor-lightBlue {
        &::before {
            background-color: #f8fbfe;
        }
    }

    &.bgColor-gray {
        &::before {
            background-color: #e9e9e9;
        }
    }

    &.bgColor-lightGray {
        &::before {
            background-color: #f9f9f9;
        }
    }

    &.scale-1 {
        width: 20px;
    }

    &.scale-2 {
        width: 30px;
    }

    &.scale-3 {
        width: 40px;
    }

    &.scale-4 {
        width: 50px;
    }

    &.scale-5 {
        width: 60px;
    }

    &.scale-6 {
        width: 70px;
    }

    &.scale-7 {
        width: 80px;
    }

    &.scale-8 {
        width: 90px;
    }

    &.scale-9 {
        width: 100px;
    }

    &.scale-10 {
        width: 120px;
    }

    &.borderScale-1 {
        &::before {
            width: calc(100% - 1px);
        }
    }

    &.borderScale-2 {
        &::before {
            width: calc(100% - 2px);
        }
    }

    &.borderScale-3 {
        &::before {
            width: calc(100% - 3px);
        }
    }

    &.borderScale-4 {
        &::before {
            width: calc(100% - 4px);
        }
    }

    &.borderScale-5 {
        &::before {
            width: calc(100% - 5px);
        }
    }

    &.black {
        background: conic-gradient(rgba(#282828, 0.04), rgba(#282828, 0.05), #000);
    }

    &.blue {
        background: conic-gradient(rgba(#dad6f9, 0.04), rgba(#dad6f9, 0.05), #1b6ae0);
    }

    &.pauseAnimation {
        animation-play-state: paused;
    }
}

.spinner {
    border: 3px solid #e0edff4a;
    border-top: 3px solid #fff;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
