import React, { FC, ReactNode, CSSProperties } from 'react';

import * as s from 'dsl_web/components/Heading.module.scss';

interface HeadingProps {
    size?: 1 | 2 | 3;
    fontWeight?: string;
    color?: string;
    className?: string;
    children?: ReactNode;
    style?: CSSProperties;
}

const Heading: FC<HeadingProps> = ({
    size = 1,
    fontWeight = 'bold',
    color = 'darkGray',
    className = '',
    children,
    ...rest
}) => {
    let heading: JSX.Element = <></>;
    if (size === 1 || !size) {
        heading = (
            <h1 className={`${s.heading} ${s[color]} ${s.h2} ${s[fontWeight]} ${className}`} {...rest}>
                {children}
            </h1>
        );
    } else if (size === 2) {
        heading = (
            <h2 className={`${s.heading} ${s[color]} ${s.h3} ${s[fontWeight]} ${className}`} {...rest}>
                {children}
            </h2>
        );
    } else if (size === 3) {
        heading = (
            <h3 className={`${s.heading} ${s[color]} ${s.h4} ${s[fontWeight]} ${className}`} {...rest}>
                {children}
            </h3>
        );
    }

    return heading;
};

export default Heading;
