.bead {
    height: 11px;
    width: 11px;
    border-radius: 100%;
}

.gray {
    background-color: #666;
}

.lightgray {
    background-color: #d4d4d4;
}

.darkGray {
    background-color: #282828;
}

.black {
    background-color: #000;
}

.white {
    background-color: #fff;
}

.green {
    background-color: #00ae00;
}

.red {
    background-color: #de350b;
}

.lightOrange {
    background-color: #ffa700;
}

.lightBlue {
    background-color: #40addc;
}
