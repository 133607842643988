.btn {
    height: 46px;
    width: 166px;
    font-size: 16px;
    transition: 150ms linear;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 4px;

    &:disabled {
        border: none;
        color: #282828;
        cursor: not-allowed;
        background-color: #e9e9e9;

        &:hover {
            color: #282828;
            background-color: #e9e9e9;
        }
    }

    &:active,
    &:focus {
        outline: solid 1px #000;
    }
}

.lightBlue {
    background-color: #00a3ff;
    color: #ffffff;

    i {
        border-style: solid;
        border-color: #666;
    }
}

.white {
    background-color: #ffffff;
    color: #000;
    border: solid 1px #000;

    i {
        border-style: solid;
        border-color: #666;
    }
}

.gray {
    background-color: #666;
    color: #dedede;
    border: none;

    i {
        border-style: solid;
        border-color: #dedede;
    }
}

.lightGray {
    background-color: #f4f7fa;
    color: #818183;
    border: none;

    i {
        border-style: 2px solid #818183;
    }
}

.black {
    background-color: #010101;
    color: #f8f8f8;
    border: none;

    i {
        border-style: solid;
        border-color: #f8f8f8;
    }
}

.blue {
    background-color: #1b6ae0;
    color: #ffffff;
    border: none;

    i {
        border-style: solid;
        border-color: #f8f8f8;
    }

    &:hover {
        background-color: #1b5de0;
    }

    &:active,
    &:focus {
        outline: solid 1px #1b6ae0;
    }
}

.blue2 {
    background-color: #3686ff;
    color: #ffffff;
    border: none;

    i {
        border-style: solid;
        border-color: #f8f8f8;
    }

    &:hover {
        background-color: #1b5de0;
    }

    &:active,
    &:focus {
        outline: solid 1px #3686ff;
    }
}

.blueText {
    background-color: transparent;
    color: #1b6ae0;
    border: 1px solid #1b6ae0;

    i {
        border-style: solid;
        border-color: #f8f8f8;
    }

    &:hover {
        background-color: #1b6ae0;
        color: #e9e9e9;
    }

    &:disabled {
        background-color: #e9e9e9;
        color: #282828;
        border: none;
    }
}

.lightBlueText {
    background-color: #ffffff;
    color: #1f95f2;
    border: solid 1px #1f95f2;

    i {
        border-style: solid;
        border-color: #666;
    }

    &:hover {
        background-color: #0086ff;
        color: #ffffff;
    }
}

.blue2Text {
    background-color: #ffffff;
    color: #3686ff;
    border: solid 1px #3686ff;

    i {
        border-style: solid;
        border-color: #666;
    }

    &:hover {
        background-color: #3686ff;
        color: #ffffff;
    }
}

.redText {
    background-color: transparent;
    color: #de350b;
    border: 1px solid #de350b;

    i {
        border-style: solid;
        border-color: #f8f8f8;
    }

    &:hover {
        background-color: #de350b;
        color: #fff;
    }

    &:disabled {
        background-color: #e9e9e9;
        color: #282828;
        border: none;
    }
}

.redText {
    background-color: transparent;
    color: #de350b;
    border: 1px solid #de350b;

    i {
        border-style: solid;
        border-color: #f8f8f8;
    }

    &:hover {
        background-color: #de350b;
        color: #e9e9e9;
    }
}

.lightGray {
    color: #282828;
    border: none;

    i {
        border-style: solid;
        border-color: #dedede;
    }

    &:hover {
        background-color: #e9e9e9;
    }
}

.lightGray1 {
    background-color: #f9f9f9;
    color: #282828;
    border: 1px solid #e9e9e9;

    i {
        border-style: solid;
        border-color: #dedede;
    }

    &:hover {
        background-color: #e9e9e9;
    }
}

.green {
    background-color: #00ae00;
    color: #fff;
    border: none;

    i {
        border-style: solid;
        border-color: #dedede;
    }

    &:hover {
        background-color: #00ae00;
    }
}

.greenPrimary {
    background-color: #0a9484;
    color: #fff;
    border: none;

    i {
        border-style: solid;
        border-color: #dedede;
    }

    &:hover {
        background-color: #0a9484;
    }
}

.red {
    background-color: #de350b;
    color: #ffffff;
    border: none;

    i {
        border-style: solid;
        border-color: #f8f8f8;
    }

    &:hover {
        background-color: #de350b;
    }
}

.darkGray {
    background-color: #282828;
    color: #f8f8f8;
    border: none;

    i {
        border-style: solid;
        border-color: #dedede;
    }

    &:hover {
        background-color: #282828;
    }
}

.darkGray1 {
    background-color: #808f99;
    color: #f8f8f8;
    border: 1px solid white;

    i {
        border-style: solid;
        border-color: #dedede;
    }
}

.darkGray2 {
    background-color: #151515;
    color: #f8f8f8;
    border: 1px solid white;

    i {
        border-style: solid;
        border-color: #dedede;
    }

    &:hover {
        background-color: #151515;
    }
}

.gold {
    background-color: #db8c00;
    border: 2px solid #ffc152;
    color: #ffffff;

    i {
        border-style: solid;
        border-color: #dedede;
    }

    &:hover {
        border: 2px solid transparent;
        background: linear-gradient(#996229, #996229) padding-box,
            linear-gradient(270deg, rgba(255, 193, 82, 1) 0%, rgba(153, 98, 41, 1) 100%) border-box;
    }
}

.yellow {
    background: #f2b61b;
    color: #f8f8f8;

    &:hover {
        background-color: #f2b61b;
    }
}

.transparent {
    border: none;
    background-color: #fff;
}

.rounded {
    border-radius: 22.5px;
}

.semiRounded {
    border-radius: 13px;
}

.arrowBtn {
    width: 22px;
    height: 100%;
    margin-left: 20px;
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow {
    border-width: 0 1px 1px 0;
    padding: 3px;
}

.down {
    margin-bottom: 4px;
    transform: rotate(45deg);
}

.lightBlueShadow {
    box-shadow: 0px 4px 23px 16px rgba(0, 134, 255, 0.28);
}

.right {
    margin-left: auto;
}

.left {
    margin-right: auto;
}

@media only screen and (max-width: 900px) {
    .btnFullSizeResponsive {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .btn {
        font-size: 14px;
    }
}
