@font-face {
    font-family: Poppins;
    src: url('web/mslm/static/public/fonts/Poppins-Regular.otf');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.text {
    width: fit-content;
}

.bold {
    font-weight: bold;
}

.medium {
    font-weight: 600;
}

.light-medium {
    font-weight: 500;
}

.normal {
    font-weight: normal;
}

.gray {
    color: #666;
}

.gray2 {
    color: #a3a3a3;
}

.gray3 {
    color: #818183;
}

.gray4 {
    color: #557098;
}

.gray5 {
    color: #999;
}

.gray6 {
    color: #383a3b;
}

.gray7 {
    color: #9c9c9c;
}

.gray8 {
    color: #919193;
}

.gray9 {
    color: #4b4747;
}

.gray10 {
    color: #cecece;
}

.gray11 {
    color: #9494a0;
}

.darkGray {
    color: #282828;
}

.lightGray {
    color: #e9e9e9;
}

.lightGray2 {
    color: #9c9c9c;
}

.lightGray3 {
    color: #818183;
}

.lightGray4 {
    color: #e6e6e6;
}

.normalGray {
    color: #acacac;
}

.normalGray2 {
    color: #cecece;
}

.black {
    color: black;
}

.black2 {
    color: #072438;
}

.black3 {
    color: #383a3b;
}

.white {
    color: white;
}

.blue {
    color: #1f95f2;
}

.blue2 {
    color: #3686ff;
}

.darkBlue {
    color: #1b6ae0;
}

.orange {
    color: #f0ad4e;
}

.gold {
    color: #db8c00;
}

.red {
    color: #f70000;
}

.green {
    color: #00ae00;
}

.lightGreen {
    color: #2dc128;
}

@media only screen and (max-width: 600px) {
    .text {
        font-size: 14px;
    }
}
