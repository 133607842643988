.pageWrapper {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.errorBoundaryImg {
    height: 120px;
    width: 120px;
    margin: auto;
    border: 10px solid #282828;
    border-radius: 30%;
    transform: rotate(45deg);
}

.errorExclamation {
    height: 73px;
    width: 10px;
    margin: 14px auto;
    transform: rotate(-45deg);
}

.errorLine {
    height: 53px;
    width: 10px;
    background-color: #282828;
    border-radius: 20px;
}
