@font-face {
    font-family: Poppins;
    src: url('web/mslm/static/public/fonts/Poppins-Regular.otf');
}

.heading {
    width: fit-content;
}

.h2 {
    font-size: 24px;
}

.h3 {
    font-size: 20px;
}

.h4 {
    font-size: 18px;
}

.bold {
    font-weight: bold;
}

.medium {
    font-weight: 600;
}

.light-medium {
    font-weight: 500;
}

.normal {
    font-weight: normal;
}

.gray {
    color: #666;
}

.darkGray {
    color: #282828;
}

.lightGray {
    color: #d6d6d6;
}

.lightGray1 {
    color: #e6e6e6;
}

.black {
    color: #000;
}

.black2 {
    color: #072438;
}

.white {
    color: white;
}

.blue {
    color: #1f95f2;
}

.blue2 {
    color: #3686ff;
}

.darkBlue {
    color: #1b6ae0;
}

.green {
    color: #02ac19;
}

.teal {
    color: #0a9483;
}

.red {
    color: #de350b;
}

@media only screen and (max-width: 600px) {
    .h2,
    .h3 {
        font-size: 18px;
    }
}
